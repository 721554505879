import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useCallback } from 'react';
// UI Kit & Components
import { useDispatch, useSelector } from 'react-redux';

import ShadowBox from 'ui-kit/shadow-box';
import ToastBox from 'ui-kit/toast-box/toast-box';

import UpdateLocationContent from 'display-components/birdi-discount/modal-contents/update-location.component';

import AddressBox from 'components/address-box';
import PrescriptionSearch from 'components/prescription-search';

import { DiscountCardErrors } from 'state/discount-card/discount-card.helpers';
import { discountCardSearchRoutine } from 'state/discount-card/discount-card.routines';
import {
    discountCardErrorSelector,
    discountCardIsLoadingSelector,
    discountCardUserLocationAddressSelector,
    discountCardUserLocationSelector
} from 'state/discount-card/discount-card.selector';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { searchDrugsByNameParams } from 'types/discount-card';

import storageHelper from 'util/storageHelper';

import { BirdiDiscountSearchBannerProps } from './birdi-discount-search-banner.props';
import './index.styles.scss';

const BirdiDiscountSearchBanner: React.FC<BirdiDiscountSearchBannerProps> = ({
    title,
    subtitle,
    image
}): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userLocationAddress = useSelector(discountCardUserLocationAddressSelector);
    const discountCardError = useSelector(discountCardErrorSelector);
    const isLoading = useSelector(discountCardIsLoadingSelector);
    const hasLocationError = discountCardError && discountCardError.messageText === DiscountCardErrors.locationNotFound;
    const hasLocation = userLocationAddress !== undefined;
    const zipCode = useSelector(discountCardUserLocationSelector);

    const handleChangeAddress = () => {
        // New dispatch function for the modal component
        dispatch(
            openModalComponent({
                title: t('pages.discountCard.updateLocation.modal.title'),
                hasDefaultFooter: false,
                hasCustomContent: true,
                content: <UpdateLocationContent handleCloseModal={() => dispatch(closeModalComponent())} />,
                variation: 'small',
                isCentered: true,
                onClose: () => dispatch(closeModalComponent())
            })
        );
    };

    const handleSubmitSearch = useCallback(
        (drugName: string) => {
            if (!hasLocation) {
                dispatch(discountCardSearchRoutine.failure({ messageText: DiscountCardErrors.locationNotFound }));
                return;
            }

            storageHelper.local.removeCurrentDrugInfo();

            const searchPayload: searchDrugsByNameParams = {
                drugName,
                zipCode: zipCode?.address?.zip
            };

            dispatch(
                discountCardSearchRoutine.trigger({
                    data: searchPayload
                })
            );
        },
        [hasLocation, zipCode]
    );

    return (
        <ShadowBox className="discount-search">
            {/* @TODO: Internationalize */}
            <div className="discount-search__container__left">
                <h2 className="discount-search__title">{title}</h2>
                <p className="discount-search__subtitle">{subtitle}</p>
                {hasLocationError && !hasLocation && (
                    <div className="discount-card__search-container-warning">
                        <ToastBox icon="warning" variant="warning">
                            <p>{t('pages.discountCard.hero.noLocationWarning')}</p>
                        </ToastBox>
                    </div>
                )}
                <div className="address-box-wrapper">
                    <AddressBox
                        variation={hasLocation ? 'default' : 'noLocation'}
                        locationAddress={userLocationAddress}
                        onChangeAddress={handleChangeAddress}
                    />
                    <PrescriptionSearch onSearch={handleSubmitSearch} isBusy={isLoading} />
                </div>
            </div>
            <div className="discount-search__container__right">
                <div className="discount-search__image-area">
                    <GatsbyImage image={getImage(image) as IGatsbyImageData} alt={'title'} />
                </div>
            </div>
        </ShadowBox>
    );
};

export default BirdiDiscountSearchBanner;
